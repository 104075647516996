/*
 * @Author: your name
 * @Date: 2021-11-23 17:37:40
 * @LastEditTime: 2022-01-24 18:52:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import Router from "vue-router";
const Home = () => import("../views/Home");

//关于上太
const About = () => import("../views/about");
const AboutProfile = () => import("../views/about/profile");

//解决方案
const Programme = () => import("../views/programme");
const ProgrammePest = () => import("../views/programme/pests");
const ProgrammeWater = () => import("../views/programme/water");
const ProgrammeProduction = () => import("../views/programme/production");
const ProgrammeHouse = () => import("../views/programme/house");
const ParentView = () => import("../components/ParentView");

// 专题
const SpecialIndex = () => import("../views/special/home");
const fx101 = () => import("../views/special/fx101");
const fx301 = () => import("../views/special/fx301");
const stld = () => import("../views/special/stld");
const x101 = () => import("../views/special/x101");
const cq101 = () => import("../views/special/cq101");
const sf101 = () => import("../views/special/sf101");
const bz101 = () => import("../views/special/bz101");
const sq101 = () => import("../views/special/sq101");
const gk101 = () => import("../views/special/gk101");
const CarTK101 = () => import("../views/special/tk101");
const Car = () => import("../views/special/xj101");
const MultifunctionalUAV = () => import("../views/special/MultifunctionalUAV");
const uva = () => import("../views/special/uva");
const yg101 = () => import("../views/special/yg101");

//新闻资讯
const News = () => import("../views/news");
const NewsIndex = () => import("../views/news/home");
const NewsDetails = () => import("../views/news/details");
const NewsMore = () => import("../views/news/more");

//社会责任
const SocietyDuty = () => import("../views/society/duty");

//服务支持
const Service = () => import("../views/support");
const ServiceContactUs = () => import("../views/support/contactus");
const ServiceJoinUs = () => import("../views/support/joinus");
const ServiceCooperation = () => import("../views/support/cooperation");
const ServiceJionDetails = () => import("../views/support/joinDetails/index.vue");

Vue.use(VueRouter);
Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      hideFooter: true,
    },
  },
  {
    path: "/about", //关于上太
    component: About,
    meta: {
      hideFooter: false,
    },
    redirect: "/about/profile", //公司简介
    children: [
      {
        path: "profile",
        name: "AboutProfile",
        component: AboutProfile,
        meta: {
          hideFooter: true,
        },
      },
    ],
  },
  {
    path: "/programme", //解决方案
    component: Programme,
    meta: {
      hideFooter: false,
    },
    redirect: "/programme/pests", //病虫害绿色防控解决方案
    children: [
      {
        path: "pests",
        name: "ProgrammePest",
        component: ProgrammePest,
        meta: {
          hideFooter: false,
        },
      },
      {
        path: "water-fertilizer", //水肥一体化解决方案
        name: "ProgrammeWater",
        component: ProgrammeWater,
        meta: {
          hideFooter: false,
        },
      },
      {
        path: "production", //智慧生产管理解决方案
        name: "ProgrammeProduction",
        component: ProgrammeProduction,
        meta: {
          hideFooter: false,
        },
      },
      {
        path: "greenhouse", //智慧温室解决方案
        name: "ProgrammeHouse",
        component: ProgrammeHouse,
        meta: {
          hideFooter: false,
        },
      },
    ],
  },

  {
    path: "/special", //产品中心
    component: ParentView,
    meta: {
      hideFooter: false,
    },
    redirect: "/special/home", //病虫害绿色防控解决方案
    children: [
      {
        path: "home",
        name: "SpecialIndex",
        component: SpecialIndex,
        meta: {
          hideFooter: false,
          title: "捕虫器101",
        },
      },

      {
        path: "fx101",
        name: "specialFx101",
        component: fx101,
        meta: {
          hideFooter: false,
          title: "捕虫器101",
        },
      },
      {
        path: "fx301",
        name: "specialFx301",
        component: fx301,
        meta: {
          hideFooter: false,
          title: "捕虫器301",
        },
      },
      {
        path: "stld",
        name: "specialStld",
        component: stld,
        meta: {
          hideFooter: false,
          title: "捕虫器ST-LD",
        },
      },
      {
        path: "x101",
        name: "specialX101",
        component: x101,
        meta: {
          hideFooter: false,
          title: "气象站X101",
        },
      },
      {
        path: "CQ101",
        name: "PestForecasting",
        component: cq101,
        meta: {
          hideFooter: false,
          title: "虫情测报",
        },
      },
      {
        path: "SF101",
        name: "specialSF101",
        component: sf101,
        meta: {
          hideFooter: false,
          title: "水肥机",
        },
      },
      {
        path: "BZ101",
        name: "specialBZ101",
        component: bz101,
        meta: {
          hideFooter: false,
          title: "孢子仪",
        },
      },
      {
        path: "SQ101",
        name: "SQ101",
        component: sq101,
        meta: {
          hideFooter: false,
          title: "墒情仪",
        },
      },
      {
        path: "GK101",
        name: "GK101",
        component: gk101,
        meta: {
          hideFooter: false,
          title: "高空测报",
        },
      },
      {
        path: "TK101",
        name: "CarTK101",
        component: CarTK101,
        meta: {
          hideFooter: false,
          title: "全地形大负载多功能无人车",
        },
      },
      {
        path: "XJ101",
        name: "Car",
        component: Car,
        meta: {
          hideFooter: false,
          title: "智能巡检无人车",
        },
      },
      {
        path: "MultifunctionalUAV",
        name: "MultifunctionalUAV",
        component: MultifunctionalUAV,
        meta: {
          hideFooter: false,
          title: "多功能无人机",
        },
      },
      {
        path: "UAV",
        name: "specialUVA",
        component: uva,
        meta: {
          hideFooter: false,
          title: "无人机",
        },
      },
      {
        path: "yg101",
        name: "yg101",
        component: yg101,
        meta: {
          hideFooter: false,
          title: "有机肥",
        },
      },
    ],
  },
  {
    path: "/news", //新闻动态
    component: News,
    meta: {
      hideFooter: false,
    },
    redirect: "/news/home", //新闻首页
    children: [
      {
        path: "home",
        name: "newshome",
        component: NewsIndex,
        meta: {
          hideFooter: false,
          title: "新闻资讯",
        },
      },
      {
        path: "details",
        name: "newsdetails",
        component: NewsDetails,
        meta: {
          hideFooter: false,
        },
      },
      {
        path: "more",
        name: "newsmore",
        component: NewsMore,
        meta: {
          hideFooter: false,
        },
      }
    ],
  },

  {
    path: "/society", //社会服务
    component: About,
    meta: {
      hideFooter: false,
    },
    redirect: "/society/duty", //社会责任
    children: [
      {
        path: "duty",
        name: "societyduty",
        component: SocietyDuty,
        meta: {
          hideFooter: false,
        },
      },
    ],
  },

  {
    path: "/support", //服务支持
    component: Service,
    meta: {
      hideFooter: false,
    },
    redirect: "/support/contactus", //联系我们
    children: [
      {
        path: "contactus",
        name: "servicecontactUs",
        component: ServiceContactUs,
        meta: {
          hideFooter: false,
        },
      },
      {
        path: "join", //加入我们
        name: "serviceJoinUs",
        component: ServiceJoinUs,
        meta: {
          hideFooter: false,
        },
      },
      {
        path: "joinDdetails",
        name: "ServiceJionDetails",
        component: ServiceJionDetails,
        meta:{
          hideFooter: false,
        }

      },
      {
        path: "cooperation", //渠道合作
        name: "serviceCooperation",
        component: ServiceCooperation,
        meta: {
          hideFooter: false,
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  next();
});

//解决路由地址重复点击的报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
