/*
 * @Author: your name
 * @Date: 2021-11-23 17:37:40
 * @LastEditTime: 2021-12-17 11:21:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAwesomeSwiper from "vue-awesome-swiper";
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import "./styles/normalize.css";
import "./styles/base.scss";
import "swiper/css/swiper.css";
import "./assets/font/font.css";
import "./assets/iconfont/iconfont.css";

Vue.use(VueAwesomeSwiper /* { default options with global component } */);
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.prototype.goTop = (el) => {
  el = el || window;
  el.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
