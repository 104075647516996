<!--
 * @Author: your name
 * @Date: 2021-12-22 17:04:15
 * @LastEditTime: 2021-12-24 16:53:34
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\components\mobile\footerItem.vue
-->
<template>
  <div class="footer-item">
    <div class="title-div" @click="$emit('check-open')">
      <span>{{ detail.title }}</span>
      <span>
        {{ isOpen ? "-" : "+" }}
      </span>
    </div>
    <ul class="child-div" v-show="isOpen">
      <li
        v-for="item in detail.children"
        :key="detail.key + '-' + item.key"
        @click="goPath(item)"
      >
        {{ item.label }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "footer-item",
  props: {
    detail: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    goPath(item) {
      this.$router.push({
        path: item.path,
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.footer-item {
  font-size: 0.24rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 12px;
  height: 0.71rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}
.child-div {
  background-color: #282828;
  font-size: 0.22rem;
  padding: 0.45rem;
  li + li {
    margin-top: 16px;
  }
}
</style>
