<!--
 * @Author: your name
 * @Date: 2021-12-22 17:02:45
 * @LastEditTime: 2023-02-10 15:27:40
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 移动端脚步
 * @FilePath: \new-website-1123\src\components\mobile\footer.vue
-->
<template>
  <div class="item-box">
    <f-item
      v-for="item in list"
      :key="item.key"
      :detail="item"
      @check-open="checkOpen(item.key)"
      :is-open="openKey == item.key"
    ></f-item>

    <div class="footer-top">
      <div class="footer-top-left">
        <img
          class="logo-img"
          src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/logo.png"
          width="114"
          alt="上太科技"
        />
        <span class="label-span">联系上太科技</span>
        <span class="col-g p-span">
          <!-- <img
              src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/phone.png"
              width="20"
              alt="上太"
            /> -->
          400-6599-888
        </span>
        <span class="col-g p-span" style="letter-spacing: 0.01rem">
          18980599999
        </span>
        <span class="label-span l-2">关注上太科技</span>
        <div class="wechat-box mt20">
          <!-- <img
            src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/wechat.png"
            alt="微信"
            width="40"
          />

          <div class="pop-box"> -->
          <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/qr.png" width="85" alt="" />
          <!-- </div> -->
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <a href="http://beian.miit.gov.cn/">© 2021版权所有 四川上太科技有限公司 蜀ICP备2021012888号-2</a>
    </div>
  </div>
</template>

<script>
import fItem from "./footerItem.vue";
export default {
  name: "",
  components: {
    fItem,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      openKey: null,
    };
  },
  created() {},
  methods: {
    checkOpen(k) {
      this.openKey = this.openKey == k ? null : k;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-box {
  width: 100%;
  background-color: #1c1c1c;
  padding: 0 6%;
  padding-bottom: 6px;
  display: flex;
  flex-direction: column;
  .footer-top {
    height: calc(100% - 52px);
    display: flex;
    padding-top: 0.13rem;
    &-left {
      display: flex;
      padding-top: 10px;
      flex-direction: column;
      min-width: 200px;
      .logo-img {
        width: 1.45rem;
        object-fit: contain;
        height: unset;
      }
      .label-span {
        color: rgba($color: #fff, $alpha: 0.4);
        margin-top: 16px;
        font-size: 0.22rem;
      }
      .p-span {
        font-size: 0.31rem;
        font-family: DIN;
        font-weight: 500;
        color: $color-active;
        margin-top: 16px;
        display: flex;
        align-items: center;
        // img {
        //   margin-right: 5px;
        // }
      }
      .wechat-box {
        position: relative;
        .pop-box {
          position: absolute;
          overflow: hidden;
          width: 0;
          height: 40px;
          bottom: 0;
          left: 40px;
          transition: all 0.3s;
        }
      }
    }
  }
  .footer-bottom {
    height: 0.4rem;
    margin-top: 0.18rem;
    border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
    font-size: 0.18rem;
    color: rgba($color: #fff, $alpha: 0.2);
    line-height: 0.4rem;
    text-align: left;
  }
}
</style>
