<!--
 * @Author: your name
 * @Date: 2021-12-22 14:13:12
 * @LastEditTime: 2021-12-24 09:09:59
 * @LastEditors: Please set LastEditors
 * @Description: 导航
 * @FilePath: \new-website-1123\src\components\mobile\nav.vue
-->
<template>
  <ul class="nav-item" :class="['level-' + level]">
    <li
      v-for="item in navList"
      :key="`${level}-${item.key}`"
      :class="{
        'is-open': isOpen == item.key,
      }"
    >
      <!-- 无子菜单 -->
      <div
        class="nav-box-div"
        @click.stop="goPath(item)"
        :class="['level-box-' + level]"
      >
        <span>
          {{ item.title || item.label }}
        </span>
        <span class="arrow-span"></span>
      </div>

      <!-- 有子菜单 -->
      <template
        v-if="item.children && item.children.length && isOpen == item.key"
      >
        <nav-item
          :nav-list="item.children"
          :level="level + 1"
          :p-key="item.key"
          @hide-menu="$emit('hide-menu')"
        ></nav-item>
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  name: "nav-item",
  props: {
    navList: {
      type: Array,
      default: () => [],
    },
    level: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {},
  methods: {
    goPath(item) {
      if (item.children && item.children.length) {
        this.isOpen = this.isOpen == item.key ? null : item.key;
      } else {
        this.$router.push({
          path: item.path,
        });
        this.$emit("hide-menu");
      }
    },
  },
};
</script>

<style lang='scss' scoped>
.nav-item {
  color: rgba($color: #fff, $alpha: 0.7);
  // padding: 0 12px;
  .nav-box-div {
    height: $mobile-header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.27rem;
    padding: 0 12px;
    padding-top: 6px;
  }
  & > li {
    &.is-open {
      background-color: #222222;
    }
  }
  &.level-1 {
    & > li > .nav-box-div {
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  &.level-2 {
    .nav-box-div {
      padding-left: 0.44rem;
    }
  }
  &.level-3 {
    .nav-box-div {
      padding-left: 1rem;
    }
  }
}
</style>
