<!--
 * @Author: your name
 * @Date: 2021-11-24 09:30:05
 * @LastEditTime: 2023-02-10 15:27:00
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 头部组件
 * @FilePath: \new-website-1123\src\components\stHeader.vue
-->
<template>
  <div class="st-header">
    <div class="header-content">
      <div class="left-div">
        <router-link to="/">
          <div class="img-logo">
            <div class="pc-giflogo"></div>
            <!-- <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/header/logo.png" width="132" alt="" /> -->
          </div>
        </router-link>
        <ul class="nav-ul">
          <li
            v-for="nav in navList"
            :key="nav.key"
            :class="{
              'nav-active':
                currentName && currentName.indexOf(nav.pathName) === 0,
            }"

          >
            <div class="pre" @click="goPath(nav.pathName)">{{ nav.title }}</div>

            <ul class="child-ul" v-if="nav.children && nav.children.length">
              <li
                v-for="child in nav.children"
                :key="'child-' + child.key"
                @click.stop="goPathPre(child.path)"
              >
                {{ child.label }}
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div class="p-div">
        <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/phone.png" alt="上太科技" />
        <div style="width: 120px">400-6599-888</div>
        <div><span>18980599999</span></div>
      </div>
    </div>
    <div class="header-mobile-content">
      <div class="title-divbox">
        <span
          class="span-t"
          :class="{
            'show-nav': showNavList,
          }"
          @click="showNavList = !showNavList"
        >
          <span></span>
          <span v-show="!showNavList"></span>
          <span></span>
        </span>
        <router-link to="/">
          <span class="img-s">
            <img
              src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/header/logo.png"
              alt="上太科技"
              style="height: 0.6rem"
            />
          </span>
        </router-link>
        <span class="span-t"></span>
      </div>
      <div
        class="nav-box"
        :style="{
          height: showNavList ? 'calc(100vh - 44px)' : '0',
        }"
      >
        <mobile-nav :navList="navMobileList" @hide-menu="hideMenu"></mobile-nav>
      </div>
    </div>
  </div>
</template>

<script>
import mobileNav from "./mobile/nav.vue";
export default {
  name: "st-header",
  components: {
    mobileNav,
  },
  data() {
    return {
      navList: [
        {
          key: 1,
          title: "首页",
          pathName: "Home",
        },
        {
          key: 2,
          title: "关于上太",
          pathName: "/about",
          children: [
            {
              key: 1,
              label: "公司简介",
              path: "/about/profile#miaost1",
            },
            {
              key: 2,
              label: "公司理念",
              path: "/about/profile#miaost2",
            },
            {
              key: 3,
              label: "公司使命",
              path: "/about/profile#miaost2",
            },
            {
              key: 4,
              label: "公司愿景",
              path: "/about/profile#miaost2",
            },
          ],
        },
        {
          key: 3,
          title: "产品中心",
          pathName: "/special",
          children: [
            {
              label: "智能虫情测报仪ST-CQ101",
              key: 6,
              path: "/special/CQ101",
            },
            {
              label: "智能高空虫情测报仪ST-GK101",
              key: 7,
              path: "/special/GK101",
            },
            {
              label: "光伏自动气象测报站ST-X101",
              key: 5,
              path: "/special/x101",
            },
            {
              label: "智能水肥机",
              key: 9,
              path: "/special/SF101",
            },
            {
              label: "太阳能墒情仪ST-SQ101",
              key: 8,
              path: "/special/SQ101",
            },
            {
              label: "智能孢子捕获仪ST-BZ101",
              key: 10,
              path: "/special/BZ101",
            },
            {
              label: "太阳能捕虫器ST-FX101",
              key: 2,
              path: "/special/fx101",
            },
            {
              label: "太阳能捕虫器ST-FX301",
              key: 3,
              path: "/special/fx301",
            },
            {
              label: "太阳能路灯捕虫器ST-LD/FX",
              key: 4,
              path: "/special/stld",
            },
            {
              label: "智能巡检无人车",
              key: 12,
              path: "/special/XJ101",
            },
            {
              label: "全地形大负载多功能无人车",
              key: 11,
              path: "/special/TK101",
            },

            {
              label: "智能巡检无人机",
              key: 14,
              path: "/special/UAV",
            },
            {
              label: "多功能无人机",
              key: 13,
              path: "/special/MultifunctionalUAV",
            },
            {
              label: "生物有机肥",
              key: 15,
              path: "/special/yg101",
            },
          ],
        },
        {
          key: 4,
          title: "解决方案",
          pathName: "/programme",
          children: [
            {
              label: "病虫害绿色防控解决方案",
              key: 3,
              path: "/programme/pests",
            },
            {
              label: "水肥一体化解决方案",
              key: 4,
              path: "/programme/water-fertilizer",
            },
            {
              label: "智慧生产管理解决方案",
              key: 5,
              path: "/programme/production",
            },

            {
              label: "智能温室解决方案",
              key: 6,
              path: "/programme/greenhouse",
            },
          ],
        },
        {
          key: 5,
          title: "新闻资讯",
          pathName: "/news",
        },
        {
          key: 6,
          title: "社会服务",
          pathName: "/society/duty",
        },
        {
          key: 7,
          title: "服务支持",
          pathName: "/support",
          children: [
            {
              key: 1,
              label: "联系我们",
              path: "/support/contactus",
            },
            {
              key: 2,
              label: "加入我们",
              path: "/support/join",
            },
            {
              key: 3,
              label: "渠道合作",
              path: "/support/cooperation",
            },
          ],
        },
      ],

      navMobileList: [
        {
          key: 1,
          title: "首页",
          path: "/",
        },
        {
          key: 2,
          title: "关于上太",
          path: "/about",
          // children: [
          //   {
          //     key: "2-1",
          //     label: "公司简介",
          //     path: "/about/profile#miaost1",
          //   },
          //   {
          //     key: "2-2",
          //     label: "公司理念",
          //     path: "/about/profile#miaost2",
          //   },
          //   {
          //     key: "2-3",
          //     label: "公司使命",
          //     path: "/about/profile#miaost2",
          //   },
          //   {
          //     key: "2-4",
          //     label: "公司愿景",
          //     path: "/about/profile#miaost2",
          //   },
          // ],
        },
        {
          key: 3,
          title: "产品中心",
          path: "/special",
          // children: [
          //   {
          //     label: "虫情测报仪",
          //     key: "3-1",
          //     children: [
          //       {
          //         label: "ST-GK101 智能高空虫情测报仪",
          //         desc: "在线精准测报，轻松搞定迁飞害虫",
          //         key: "3-1-1",
          //         path: "/special/GK101",
          //       },
          //       {
          //         label: "ST-GQ101 智能虫情测报仪",
          //         desc: "在线精准测报，轻松搞定迁飞害虫",
          //         key: "3-1-2",
          //         path: "/special/CQ101",
          //       },
          //     ],
          //   },
          //   {
          //     label: "气象测报站",
          //     key: "3-2",
          //     children: [
          //       {
          //         label: "ST-QX101 光伏自动气象测报站",
          //         desc: "",
          //         key: "3-2-1",
          //         path: "/special/x101",
          //       },
          //     ],
          //   },
          //   {
          //     label: "墒情仪",
          //     key: "3-3",
          //     children: [
          //       {
          //         label: "ST-SQ101 太阳能墒情仪",
          //         desc: "",
          //         key: "3-3-1",
          //         path: "/special/SQ101",
          //       },
          //     ],
          //   },
          //   {
          //     label: "水肥机",
          //     key: "3-4",
          //     children: [
          //       {
          //         label: "智慧水肥机",
          //         desc: "",
          //         key: "3-4-1",
          //         path: "/special/SF101",
          //       },
          //     ],
          //   },
          //   {
          //     label: "孢子仪",
          //     key: "3-5",
          //     children: [
          //       {
          //         label: "ST-BZ101 智能袍子捕获仪",
          //         desc: "",
          //         key: "3-5-1",
          //         path: "/special/BZ101",
          //       },
          //     ],
          //   },
          //   {
          //     label: "捕虫器",
          //     key: "3-6",
          //     children: [
          //       {
          //         label: "ST-FX101 太阳能捕虫器",
          //         desc: "",
          //         key: "3-6-1",
          //         path: "/special/fx101",
          //       },
          //       {
          //         label: "ST-FX301 太阳能捕虫器",
          //         desc: "",
          //         key: "3-6-2",
          //         path: "/special/fx301",
          //       },
          //       {
          //         label: "ST-LD/FX 太阳能路灯捕虫器",
          //         desc: "",
          //         key: "3-6-3",
          //         path: "/special/stld",
          //       },
          //     ],
          //   },
          //   {
          //     label: "无人车",
          //     key: "3-7",
          //     children: [
          //       {
          //         label: "智能巡检无人车",
          //         desc: "",
          //         key: "3-7-1",
          //         path: "/special/XJ101",
          //       },
          //       {
          //         label: "全地形大负载多功能无人车",
          //         desc: "",
          //         key: "3-7-2",
          //         path: "/special/TK101",
          //       },
          //     ],
          //   },
          //   {
          //     label: "无人机",
          //     key: "3-8",
          //     children: [
          //       {
          //         label: "智能巡检无人机",
          //         desc: "",
          //         key: "3-8-1",
          //         path: "/special/UAV",
          //       },
          //       {
          //         label: "多功能无人机",
          //         desc: "",
          //         key: "3-8-2",
          //         path: "/special/MultifunctionalUAV",
          //       },
          //     ],
          //   },
          // ],
        },
        {
          key: 4,
          title: "解决方案",
          path: "/programme",
          children: [
            {
              label: "病虫害绿色防控解决方案",
              key: "4-1",
              path: "/programme/pests",
            },
            {
              label: "水肥一体化解决方案",
              key: "4-2",
              path: "/programme/water-fertilizer",
            },
            {
              label: "智慧生产管理解决方案",
              key: "4-3",
              path: "/programme/production",
            },

            {
              label: "智能温室解决方案",
              key: "4-4",
              path: "/programme/greenhouse",
            },
          ],
        },
        {
          key: 5,
          title: "新闻资讯",
          path: "/news",
        },
        {
          key: 6,
          title: "社会服务",
          path: "/society/duty",
        },
        {
          key: 7,
          title: "服务支持",
          path: "/support",
          children: [
            {
              key: "7-1",
              label: "联系我们",
              path: "/support/contactus",
            },
            {
              key: "7-2",
              label: "加入我们",
              path: "/support/join",
            },
            {
              key: "7-3",
              label: "渠道合作",
              path: "/support/cooperation",
            },
          ],
        },
      ],

      showNavList: false,
    };
  },
  created() {},
  computed: {
    currentName() {
      if (this.$route.name == "Home") {
        return this.$route.name;
      } else {
        return this.$route.path;
      }
    },
  },
  methods: {
    /**
     * @description: 隐藏移动端的菜单
     */
    hideMenu() {
      this.showNavList = false;
    },
    /**
     * @description: 跳转页面
     * @param {*} name
     */
    goPath(name) {
      if (!name) return;
      if (name.includes("/")) {
        this.$router.push(name);
      } else {
        this.$router.push({
          name,
        });
      }
    },
    goPathPre(name,e){
      if (!name) return;
      if (name.includes("/")) {
        this.$router.push(name);
      } else {
        this.$router.push({
          name,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.st-header {
  height: 80px;
  display: flex;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  background-color: #111111;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.5),
    rgba(0, 0, 0, 0)
  );
  transition: all 0.3s;
  &:hover {
    background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 1)
    );
  }
  .pre {
    width: 100%;
    height: 100%;
  }
  // background: #000;
  .pc-giflogo {
    width: 132px;
    height: 50px;
    background: url('https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/header/10.png') no-repeat center;
    background-size: auto 50px;
  }
  .pc-giflogo:hover {
    background: url('https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/header/10.gif') no-repeat center;
    background-size: auto 50px;
    width: 132px;
    height: 50px;
  }
  .header-content {
    height: 100%;
    width: 78%;
    max-width: 1500px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-content: center;
    color: #fff;
    & > div {
      display: flex;
      align-items: center;
    }
    .left-div {
      width: calc(100% - 150px);
    }
    .nav-ul {
      color: #fff;
      width: calc(100% - 160px);
      display: flex;
      margin-left: 20px;
      font-size: 16px;
      height: 100%;
      align-items: center;
      & > li {
        line-height: 80px;
        cursor: pointer;
        position: relative;
        height: 100%;
        white-space: nowrap;
        &.nav-active {
          color: $color-active;
        }
        .child-ul {
          position: absolute;
          top: 80px;
          left: 50%;
          transform: translateX(-50%);
          max-height: 0;
          overflow: hidden;
          padding: 0;
          transition: all 0.3s;
          background: #161616;
          li {
            font-size: 12px;
            line-height: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #fff;
            white-space: nowrap;
            & + li {
              margin-top: 16px;
            }
            &:hover {
              color: $color-active;
            }
          }
        }
        &::after {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          content: "";
          width: 0;
          height: 2px;
          background-color: $color-active;
          transition: width 0.3s;
        }
        &:hover {
          &::after {
            width: 100%;
          }
          & .child-ul {
            padding: 18px;
            max-height: 500px;
            // overflow: auto;
          }
        }
      }
    }
  }
  .p-div {
    display: flex;
    align-items: center;
    font-size: 16px;
    img {
      margin-right: 8px;
    }
  }

  .header-mobile-content {
    width: 100%;
    .title-divbox {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .img-s {
        display: flex;
        align-items: center;
        img {
          display: block;
        }
      }
      .span-t {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        height: 24px;
        width: 24px;
        span {
          width: 100%;
          height: 2px;
          background-color: #fff;
        }
        &.show-nav {
          span {
            &:first-child {
              transform: translateY(7px) rotateZ(45deg);
            }
          }
          span:last-child {
            transform: translateY(-5px) rotateZ(-45deg);
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1500px) {
  .st-header {
    .header-content {
      .nav-ul {
        & > li {
          margin-left: 6.8%;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .st-header {
    .header-content {
      .nav-ul {
        & > li {
          margin-left: 4%;
        }
      }
    }
  }
}
@media screen and (max-height: 850px) {
  .st-header {
    height: 60px;
    .header-content {
      .nav-ul {
        width: calc(100% - 160px);
        & > li {
          line-height: 60px;
          .child-ul {
            top: 60px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: $mobile-width) {
  .header-content {
    display: flex;
  }
  .header-mobile-content {
    display: none;
  }
}

@media screen and (max-width: $mobile-width) {
  .st-header {
    height: 1rem;
    width: 100vw;
    background-color: $color-mobile-theme;
    background-image: unset;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0 14px;
    .header-content {
      display: none;
    }
    &:hover {
      background-color: #161616;
      background-image: none;
    }
  }
  .header-mobile-content {
    display: block;
    background-color: $color-mobile-theme;
    .nav-box {
      height: calc(100vh - 1rem);
      position: absolute;
      background-color: $color-mobile-theme;
      top: 1rem;
      left: 0;
      right: 0;
      bottom: 0;
      // padding: 12px;
      padding-top: 0;
      overflow-y: auto;
    }
  }
}
</style>
