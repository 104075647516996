<!--
 * @Author: your name
 * @Date: 2021-11-24 09:30:11
 * @LastEditTime: 2023-02-01 16:21:56
 * @LastEditors: 韩鸿 1014315067@qq.com
 * @Description: 脚部组件
 * @FilePath: \new-website-1123\src\components\stFooter.vue
-->
<template>
  <div class="st-footer">
    <div class="footer-content">
      <div class="footer-top">
        <div class="footer-top-left">
          <img
            class="logo-img"
            src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/logo.png"
            width="114"
            alt="上太科技"
          />
          <span class="label-span">联系上太科技</span>
          <span class="col-g p-span">
            400-6599-888
          </span>
          <span
            class="col-g p-span"
            style="letter-spacing: 0.6px; margin-top: 8px"
          >
            18980599999
          </span>
          <span class="label-span l-2">关注上太</span>
          <div class="wechat-box ">
            <!-- <img
              src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/wechat.png"
              alt="微信"
              width="40"
            /> -->

            <div class="pop-box">
              <img src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/qr.png" width="85" alt="" />
            </div>
          </div>
        </div>
        <ul class="footer-top-right">
          <li v-for="item in dataList" :key="'item' + item.key">
            <span class="title-span">{{ item.title }}</span>
            <ul class="child-ul">
              <li
                v-for="child in item.children"
                :key="'child-' + child.key"
                @click.stop="goPath(child.path)"
              >
                {{ child.label }}
              </li>
            </ul>
          </li>
          <div class="footer_img">
            <a
              href="https://xyt.xcc.cn/getpcInfo?sn=1610459584915283968&certType=8&url=*.stkjiot.com&language=CN"
              target="_blank"
              style="position: relative; display: inline-block; height: 38px"
            >
              <div
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                "
              ></div>
              <embed
                src="https://program.xinchacha.com/web/1610459584915283968=*.stkjiot.com.svg"
                width="103"
                height="38"
                type="image/svg+xml"
                pluginspage="//www.adobe.com/svg/viewer/install/"
              />
            </a>
          </div>
        </ul>
      </div>
      <div class="footer-bottom">
        <a href="http://beian.miit.gov.cn/"
          >© 2021版权所有 四川上太科技有限公司 蜀ICP备2021012888号-2</a
        >
      </div>
    </div>
    <div class="footer-mobile-content">
      <mobile-footer :list="dataList"></mobile-footer>
    </div>
  </div>
</template>

<script>
import mobileFooter from "./mobile/footer";
export default {
  name: "footer-comp",
  components: {
    mobileFooter,
  },
  data() {
    return {
      dataList: [
        {
          key: 1,
          title: "热销产品",
          children: [
            {
              label: "智能虫情测报仪ST-CQ101",
              key: 2,
              path: "/special/CQ101",
            },
            {
              label: "智能高空虫情测报仪ST-GK101",
              key: 3,
              path: "/special/GK101",
            },
            {
              label: "光伏自动气象测报站ST-QX101",
              key: 4,
              path: "/special/x101",
            },
            {
              label: "智能水肥机ST-SF101",
              key: 5,
              path: "/special/SF101",
            },
            {
              label: "太阳能墒情仪ST-SQ101",
              key: 6,
              path: "/special/SQ101",
            },
          ],
        },
        {
          key: 2,
          title: "解决方案",
          children: [
            {
              label: "病虫害绿色防控解决方案",
              key: 3,
              path: "/programme/pests",
            },
            {
              label: "水肥一体化解决方案",
              key: 4,
              path: "/programme/water-fertilizer",
            },
            {
              label: "智能生产管理解决方案",
              key: 5,
              path: "/programme/production",
            },
            {
              label: "智能温室解决方案",
              key: 6,
              path: "/programme/greenhouse",
            },
          ],
        },
        {
          key: 3,
          title: "社会服务",
          children: [
            {
              key: 1,
              label: "公益计划",
              path: "/society/duty#socio1",
            },
            {
              key: 2,
              label: "校企合作",
              path: "/society/duty#socio2",
            },
            {
              key: 3,
              label: "助农行动",
              path: "/society/duty#socio3",
            },
          ],
        },
        {
          key: 4,
          title: "服务支持",
          children: [
            {
              key: 1,
              label: "加入我们",
              path: "/support/join",
            },
            {
              key: 2,
              label: "联系我们",
              path: "/support/contactus",
            },
            {
              key: 3,
              label: "渠道合作",
              path: "/support/cooperation",
            },
          ],
        },
      ],
    };
  },
  created() {},
  methods: {
    /**
     * @description: 跳转页面
     * @param {*} name
     */
    goPath(name) {
      if (!name) return;
      if (name.includes("/")) {
        this.$router.push(name);
      } else {
        this.$router.push({
          name,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.st-footer {
  height: 402px;
  width: 100%;
  background-color: #1d1d1d;
  .footer-content {
    margin: auto;
    width: 75%;
    max-width: 1495px;
    padding-top: 50px;
    height: 100%;
    .footer-top {
      height: calc(100% - 52px);
      display: flex;
      position: relative;
      &-left {
        display: flex;
        padding-top: 10px;
        flex-direction: column;
        min-width: 200px;
        .logo-img {
          width: 114px;
          object-fit: contain;
          height: unset;
        }
        .label-span {
          color: rgba($color: #fff, $alpha: 0.4);
          margin-top: 14px;
          font-size: 14px;
          &.l-2 {
            margin-top: 34px;
          }
        }
        .p-span {
          font-size: 24px;
          font-family: DIN;
          font-weight: 500;
          color: $color-active;
          margin-top: 16px;
          display: flex;
          align-items: center;
          // img {
          //   margin-right: 5px;
          // }
        }
        .wechat-box {
          position: relative;
          // &:hover {
          //   .pop-box {
          //     width: 85px;
          //     height: 85px;
          //   }
          // }
          width: 59px;
            height: 59px;
           flex:1;
           display: flex;
          align-items: center;
          justify-content: center;

          .pop-box {
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
      &-right {
        width: calc(100% - 200px);
        display: flex;
        justify-content: space-between;
        margin-left: 30px;
        padding-top: 8px;
        .title-span {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 300;
          color: #ffffff;
        }
        .child-ul {
          margin-top: 40px;
          font-size: 14px;
          font-family: PingFang SC;
          color: rgba($color: #fff, $alpha: 0.6);
          li {
            cursor: pointer;
            & + li {
              margin-top: 26px;
            }
          }
        }
      }
      .footer_img {
        position: absolute;
        right:-27px;
        top: 210px;
      }
    }
    .footer-bottom {
      height: 52px;
      border-top: 1px solid rgba($color: #fff, $alpha: 0.2);
      font-size: 14px;
      color: rgba($color: #fff, $alpha: 0.2);
      line-height: 52px;
      text-align: center;
    }
  }
}
@media screen and (min-width: 1600px) {
  .st-footer {
    .footer-content {
      .footer-top {
        &-right {
          margin-left: 260px;
        }
      }
    }
  }
}
@media screen and (max-width: 1500px) {
  .st-footer {
    height: 300px;
    .footer-content {
      width: 80%;
      padding-top: 20px;
      .footer-top {
        height: calc(100% - 30px);
        &-left {
          .label-span {
            margin-top: 20px;
            &.l-2 {
              margin-top: 24px;
            }
          }
          .wechat-box {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .p-span {
            margin-top: 8px;
          }
        }
        &-right {
          .title-span {
            font-size: 18px;
          }
          .child-ul {
            margin-top: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            color: rgba($color: #fff, $alpha: 0.6);
            li {
              cursor: pointer;
              & + li {
                margin-top: 16px;
              }
            }
          }
        }
      }
      .footer-bottom {
        height: 30px;
        line-height: 30px;
      }
    }
  }
}

@media screen and (max-height: 800px) {
  .st-footer {
    height: 300px;
    .footer-content {
      padding-top: 20px;
      .footer-top {
        &-left {
          .label-span {
            margin-top: 20px;
            &.l-2 {
              margin-top: 24px;
            }
          }
          .p-span {
            margin-top: 8px;
          }
        }
        &-right {
          .title-span {
            font-size: 18px;
          }
          .child-ul {
            margin-top: 20px;
            font-size: 14px;
            font-family: PingFang SC;
            color: rgba($color: #fff, $alpha: 0.6);
            li {
              cursor: pointer;
              & + li {
                margin-top: 16px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: $mobile-width) {
  .footer-content {
    display: block;
  }
  .footer-mobile-content {
    display: none;
  }
}

@media screen and (max-width: $mobile-width) {
  .st-footer {
    .footer-content {
      display: none;
    }
  }
  .footer-mobile-content {
    display: flex;
  }
}
</style>
