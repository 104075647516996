<!--
 * @Author: your name
 * @Date: 2021-11-23 17:37:40
 * @LastEditTime: 2022-01-26 10:10:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\App.vue
-->
<template>
  <div id="app">
    <!-- 头部菜单 -->
    <st-header></st-header>
    <!-- 内容 -->
    <transition
      name="page"
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
    >

      <router-view></router-view>
    </transition>
    <!-- 脚步信息 -->
    <st-footer v-if="!$route.meta.hideFooter"></st-footer>
  </div>
</template>
<script>
import stHeader from "./components/stHeader.vue";
import stFooter from "./components/stFooter.vue";
export default {
  components: {
    stHeader,
    stFooter,
  },
  mounted() {
    this.cscroll();
    window.addEventListener("scroll", this.cscroll, false);
  },
  methods: {
    /**
     * @description:
     * @param {*}
     * @return {*}
     */
    cscroll() {
      if (window.innerWidth > 800) {
        let html = document.querySelector("html");
        let opa = document.querySelectorAll(".opacity-page");
        let scale = document.querySelectorAll(".scale-page");
        let sticky = document.querySelectorAll(".sticky-page");
        let topHide = document.querySelectorAll(".top-hide-page");
        let wHeight = window.innerHeight;
        for (let i = 0; i < opa.length; i++) {
          const element = opa[i];
          let chaHeight = element.offsetTop - html.scrollTop - 80;
          chaHeight = chaHeight < 0 ? 0 : chaHeight;
          let rate = chaHeight / wHeight > 1 ? 1 : chaHeight / wHeight;
          element.style.opacity = 1 - rate * 1.4;
        }
        for (let i = 0; i < scale.length; i++) {
          const element = scale[i];
          let chaHeight = element.offsetTop - html.scrollTop - 80;
          chaHeight = chaHeight < 0 ? 0 : chaHeight;
          let rate = chaHeight / wHeight > 1 ? 1 : chaHeight / wHeight;
          element.style.transform = `scale(${1 - rate * 0.8})`;
        }
        for (let i = 0; i < sticky.length; i++) {
          const element = sticky[i];
          let prev = element.previousElementSibling;
          let chaTop = element.offsetTop - prev.offsetTop;
          if (chaTop > 0) {
            let wRa = element.offsetTop - prev.offsetTop - 100;
            let rate = wRa / wHeight;
            if (wRa > 0) {
              if (rate < 0.8) {
                // element.style.opacity = 1 - rate;
                element.style.opacity = 1;
              } else {
                element.style.opacity = 0;
              }
            } else {
              element.style.opacity = 1;
            }
          }
        }
        for (let i = 0; i < topHide.length; i++) {
          const element = topHide[i];
          let chaTop = element.offsetTop - html.scrollTop;
          if (chaTop < 0) {
            chaTop = Math.abs(chaTop);
            let wRa = wHeight / 2;
            chaTop = chaTop > wRa ? wRa : chaTop;
            chaTop = chaTop / wRa;
            element.style.opacity = 1 - chaTop;
          }
        }
      }
    }

  },
};
</script>
