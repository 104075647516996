var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"st-header"},[_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"left-div"},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"img-logo"},[_c('div',{staticClass:"pc-giflogo"})])]),_c('ul',{staticClass:"nav-ul"},_vm._l((_vm.navList),function(nav){return _c('li',{key:nav.key,class:{
            'nav-active':
              _vm.currentName && _vm.currentName.indexOf(nav.pathName) === 0,
          }},[_c('div',{staticClass:"pre",on:{"click":function($event){return _vm.goPath(nav.pathName)}}},[_vm._v(_vm._s(nav.title))]),(nav.children && nav.children.length)?_c('ul',{staticClass:"child-ul"},_vm._l((nav.children),function(child){return _c('li',{key:'child-' + child.key,on:{"click":function($event){$event.stopPropagation();return _vm.goPathPre(child.path)}}},[_vm._v(" "+_vm._s(child.label)+" ")])}),0):_vm._e()])}),0)],1),_vm._m(0)]),_c('div',{staticClass:"header-mobile-content"},[_c('div',{staticClass:"title-divbox"},[_c('span',{staticClass:"span-t",class:{
          'show-nav': _vm.showNavList,
        },on:{"click":function($event){_vm.showNavList = !_vm.showNavList}}},[_c('span'),_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showNavList),expression:"!showNavList"}]}),_c('span')]),_c('router-link',{attrs:{"to":"/"}},[_c('span',{staticClass:"img-s"},[_c('img',{staticStyle:{"height":"0.6rem"},attrs:{"src":"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/header/logo.png","alt":"上太科技"}})])]),_c('span',{staticClass:"span-t"})],1),_c('div',{staticClass:"nav-box",style:({
        height: _vm.showNavList ? 'calc(100vh - 44px)' : '0',
      })},[_c('mobile-nav',{attrs:{"navList":_vm.navMobileList},on:{"hide-menu":_vm.hideMenu}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-div"},[_c('img',{attrs:{"src":"https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/images/footer/phone.png","alt":"上太科技"}}),_c('div',{staticStyle:{"width":"120px"}},[_vm._v("400-6599-888")]),_c('div',[_c('span',[_vm._v("18980599999")])])])}]

export { render, staticRenderFns }